import React from "react"

import Layout from "./../../../components/layout"
import SEO from "./../../../components/seo"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SaveYourCompanyFromHackersAndDisastersCore from "../core/save-your-company-from-hackers-and-disasters"
import ContactFormGrey from "../../../components/generic/contact-form-grey"

const SaveYourCompanyFromHackersAndDisasters = () => {
  const data = useStaticQuery(query)
  const specterDbaName = data.specterData.siteMetadata.company.name.dba
  const specterEmail = data.specterData.siteMetadata.company.contact.email
  const windowsUpdateNotification =
    data.windowsUpdateNotification.childImageSharp.fluid
  const metasploitExample = data.metasploitExample.childImageSharp.fluid
  const cveList = data.cveList.childImageSharp.fluid
  const fakeEmailFromBob = data.fakeEmailFromBob.childImageSharp.fluid

  return (
    <Layout landingPage={true}>
      <SEO
        title="Save your company from hackers and disasters! And, learn!"
        description="Read all about how we, as an exclusive and premier IT managed service provider, can save you from hackers and disasters. Plus, learn!"
      />
      <SaveYourCompanyFromHackersAndDisastersCore
        email={specterEmail}
        buttonText={"Get It Here!"}
        ctaText={
          <span>
            Get a <em>FREE</em> business IT infrastructure{" "}
            <em>security assessment here</em>!
          </span>
        }
        campaignId={"4435c0a8-70cf-4982-9d15-18b5ec808e07"}
        campaignVariant={"B"}
        phoneIsVisible={true}
        emailIsVisible={true}
      >
        <section className="save-your-company-from-hackers-and-disasters-page__detail">
          <div className="save-your-company-from-hackers-and-disasters-page__detail-content">
            <h2 className="heading-secondary save-your-company-from-hackers-and-disasters-page__heading-secondary">
              If your business is not prepared, <em>getting hacked is easy</em>!
              Let's take a look at some{" "}
              <em>common reasons businesses get hacked</em>.
            </h2>
            <ul className="save-your-company-from-hackers-and-disasters-page__list">
              <li>Bad passwords</li>
              <li>Deprecated software</li>
              <li>Failing to apply updates</li>
              <li>Poorly written software</li>
              <li>Leaving default settings on</li>
              <li>Employee training deficits</li>
              <li>And, many more...</li>
            </ul>
            <h2 className="heading-secondary save-your-company-from-hackers-and-disasters-page__heading-secondary">
              Let's look at an <em>example</em>. Let's consider a case of
              failing to apply&nbsp;
              <em>software updates</em> and <em>employee training deficits</em>.
            </h2>

            <div className="save-your-company-from-hackers-and-disasters-page__display-image-wrapper">
              <Img
                title="Windows 10 Update Dialog Box"
                alt="An example Windows 10 Update dialog box, requesting a user to apply updates."
                fluid={windowsUpdateNotification}
              />
            </div>
            <h3 className="heading-tertiary save-your-company-from-hackers-and-disasters-page__heading-tertiary">
              Overview
            </h3>
            <p className="heading-secondary save-your-company-from-hackers-and-disasters-page__paragraph">
              It's exceedingly easy to just hit 'Remind me later'. People make
              that mistake all the time!
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              In fact, many people simply attempt to find ways to completely
              disable their Windows Updates, and other important software
              updates, altogether! But,{" "}
              <em>ignoring or disabling updates is a huge problem</em>.
              Especially when you're a{" "}
              <em>business and therefore a target for hackers</em>.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              These updates exist for an incredibly important reason most of the
              time. Not all of them are simply features.{" "}
              <em>They often include security updates</em>. And, where an update
              lurks, you can rest assured that most of the time there will be a{" "}
              <em>Common Vulnerabilities and Exposures (CVE)</em> entry pending.
              More on CVE's later.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              What we have when you combine the inevitable human error and a
              system or network primed for attack is a{" "}
              <em>recipe for disaster</em>. In response to this recipe for
              disaster, this is a somewhat over-simplified example story of{" "}
              <em>
                how a hacker might act opportunistically to exploit your company
              </em>{" "}
              for monetary gain or personal pleasure.
            </p>
            <h3 className="heading-tertiary save-your-company-from-hackers-and-disasters-page__heading-tertiary">
              Step 1: Reconnaissance
            </h3>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              Knowing that employees often make poor decisions about information
              security and often default to trusting behavior, the hacker
              decides to investigate the companies employees.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              Apparently the company has a listing on LinkedIn, an up-to-date
              company directory, and several public events where employees
              attended and were listed. This is more than enough information to
              work from without having to perform more painstaking work of
              active recon through phone calls, etc.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              Although this company is relatively small at 20 employees, this is
              more than enough to work with and the hacker moves forward by
              creating a short-list of who should be investigated first. Suzy
              (CEO), Jim (Field Manager), Bob (Operations Manager), and Jill
              (Human Resources Manager).
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              Now the hacker proceeds to perform a more detailed investigation
              into the above four individuals. Of them, Bob the Operations
              Manager really stands out. He has a Facebook profile where he
              constantly rants about technology being the bane of his existence,
              reminiscing upon the better days of the past when things were
              simpler and more effecient (from his perspective). Some of this
              comes through on several posts, indicating he doesn't know how to
              use the edit feature when something goes wrong.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              Suzy is obviously interesting as the CEO. She even has her email
              address listed on her LinkedIn profile. But, she appears to be
              pretty tech savvy and will undoubtedly be difficult to get in
              touch with.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              Bob, on the other hand, is perfect. He seems to be technologically
              disinclined, and a bit jaded. That's exploitable. Unfortunately,
              other than Facebook, the hacker doesn't see any contact
              information listed.
            </p>
            <h3 className="heading-tertiary save-your-company-from-hackers-and-disasters-page__heading-tertiary">
              Step 2: Social Engineering
            </h3>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              The hacker then starts to learn just enough about the industry to
              be convincing in short conversations. After doing so, the hacker
              sets out on a series of calls over days to weeks talking to
              different employees. Eventually, Bob's phone number is acquired.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              This particular hacker is new to the field, so rather than attempt
              technical attacks that may be logged on servers or be picked up as
              abnormal behavior by the person in charge of IT, he decides to
              take a more simple approach.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              The hacker is simply going to ask Bob for the information he
              needs. Here is the conversation that takes place between the
              hacker and Bob. Yes, it's a fictional conversation. But, it's
              fairly realistic.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              <em>Hacker:</em> Hi Bob, this is Jarod over at Microsoft's
              Customer Service department. We've had an abnormally large number
              of error messages originating from your company delivered through
              Microsoft Word error reporting systems. I spoke to Jill in HR a
              couple weeks back, and she informed me that you'd be a great
              person to talk to as you've experienced some problems on your
              machine in the past.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              <em>Bob:</em> Hi Jarod. I'm a bit busy right now, but I'll do what
              I can to help. What questions do you have for me?
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              <em>Hacker:</em> Oh, this is easy. It will only take about 2
              minutes. I just need to see which version of Microsoft Office is
              installed on your system, and what version of Windows you're
              running. (NOTE: the assumption that he's on a Microsoft Windows
              computer with Microsoft Office on it is a fair one. The lion's
              share of businesses use Microsoft Products. And, even if he was
              wrong on the software, the hacker could just pivot to a different
              strategy later.)
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              (Hacker provides instructions. Bob does as he's asked.)
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              <em>Bob:</em> Microsoft Office 2013 Service Pack 1 on Windows 7
              Service Pack 1.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              <em>Hacker:</em> Thanks so much, Bob! I really appreciate it and
              I'll be out of your hair now. Please have a nice rest of your day.
              (Unstated: There is definitely a <em>CVE</em> for that setup!)
            </p>

            <h3 className="heading-tertiary save-your-company-from-hackers-and-disasters-page__heading-tertiary">
              Step 3: Taking Action
            </h3>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              So, the hacker now gets to work looking for easy exploits of this
              old system. He'll start with low-hanging fruit in CVE's, or common
              vulnerabilities and exposures. These can be acquired easily via
              Google search.
            </p>
            <div className="save-your-company-from-hackers-and-disasters-page__display-image-wrapper">
              <Img
                title="Search result set of 819 Common Vulnerabilities and Exposures for Microsoft Office"
                alt="A Mitre.org search result set of 819 Common Vulnerabilityes and  for Microsoft Office is displayed in an image."
                fluid={cveList}
              />
            </div>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              You'll see above{" "}
              <em>there are 819 CVE's listed under Microsoft Office alone</em>!
              The hacker then decides to go with <em>CVE 2017-0199</em>, which
              uses a fake Microsoft Word document with an embedded script to
              gain a remote connection to the machine that opens the program.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              The hacker then recognizes that this is a very vulnerable system
              on what is likely a very aged IT infrastructure. He's got a wealth
              of opportunity, and to date he's done almost nothing that would be
              suspicious.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              The hacker reviews the list and ultimately decides{" "}
              <em>he can take the simple "Script Kiddie" approach</em>. (Script
              Kiddie is a disparaging term that hackers and information security
              professionals use to describe unskilled individals in the field
              who benefit from easy targets and tools created by much more
              knowledgeable individuals.) The hacker then{" "}
              <em>fires up his Kali Linux box</em> (a hacking-enabled operating
              system) and <em>launches Metasploit </em>(a software program which
              has a repository of scripts used in exploiting machines.)
            </p>

            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              No surprise. Metasploit already has a module that can be easily
              leveraged to take advantage of CVE 2017-0199! This will be easy!
              He just has to provide a few parameters to the Metasploit module
              and hit run. He'll then have a malicious Microsoft Word document.
              He'll send this document to the CEO, Suzy, pretending to be Bob.
              Ultimately the hacker will be capable of connecting Suzy's
              computer to his own through a server listening on his own computer
              for Suzy's connection after she opens or previews the file.
            </p>

            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              So, the hacker does just that. Runs the Metasploit exploit against
              CVE 2017-0199.
            </p>

            <div className="save-your-company-from-hackers-and-disasters-page__display-image-wrapper">
              <Img
                title="Metasploit Console in Kali Linux"
                alt={
                  "A " +
                  specterDbaName +
                  " employee finding CVE 2017-0199 on Metasploit, demonstrating how easy it is to exploit vulnerable machines."
                }
                fluid={metasploitExample}
              />
            </div>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              If you're feeling adventurous and want to get an inside peek at
              what it's like to find the CVE and to use the Metasploit exploit
              of CVE 2017-0199 in action (hint: easy),{" "}
              <em>please feel free to watch the video below</em>. The video
              below&nbsp;
              <em>
                shows how in only a few minutes you can identify a CVE and
                create a malicious exploit that can take control of a remote
                computer
              </em>{" "}
              that is vulnerable in the way we described above - a very common
              scenario.
            </p>
            <div className="save-your-company-from-hackers-and-disasters-page__display-image-wrapper">
              <iframe
                title={
                  "Video of " +
                  specterDbaName +
                  " employee demonstrating exploitation of CVE 2017-0199 on Metasploit."
                }
                className="save-your-company-from-hackers-and-disasters-page__educational-video"
                src="https://www.youtube.com/embed/FMt8DJ--gyc"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              Now that the hacker has created an infected Microsoft Word
              document, it's ready to deploy.{" "}
              <em>
                The last part, or the deployment of the hack, is fairly simple
              </em>
              . Simply <em>write an email, and send the attachment</em>.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              There are two obvious routes the hacker could take to send the
              email. One, simply hack Bob first and send directly from his
              personal email account. This would be the hardest to detect, but
              have some additional time requirements. Two, <em>spoof</em> the
              email. That is, you can hack emails to make them look like they're
              coming from someone they are not. This is a little more vulnerable
              to the savvy user discovering it, but can be done very simply.
              This time, the hacker decides to use Bob's computer by sending Bob
              -- the less technically savvy employee -- a spoofed email first.
              It's going to be harder to trick Suzy.
            </p>
            <div className="save-your-company-from-hackers-and-disasters-page__display-image-wrapper">
              <Img
                title="Fake email crafted by hypothetical hacker being sent to Bob at Fictitious Corp."
                alt="Fake email crafted by hypothetical hacker being sent to Bob at Fictitious Corp."
                fluid={fakeEmailFromBob}
              />
            </div>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              Assuming Suzy has the same programs and versions installed as
              Bob,&nbsp;
              <em>
                if she so much as peeks at the preview of the document the
                malicious script embedded in the word document will run and the
                hacker will have received access to the CEO's computer
              </em>
              , and everything in it and connected to it, with{" "}
              <em>
                hardly more than a few minutes of work, some google searches,
                and a few phone calls
              </em>
              .
            </p>
            <h2 className="heading-secondary save-your-company-from-hackers-and-disasters-page__heading-secondary">
              This is <em>just one very simple method, of countless</em>, for
              hackers to penetrate and{" "}
              <em>exploit modern IT infrastructures</em>.
            </h2>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              <em>No business is 100% safe from hackers</em>. But,{" "}
              <em>
                you can drastically reduce your odds of being hacked and just as
                importantly, also protect yourself from the damage
              </em>{" "}
              if you are hacked by being pro-active. It's a non-stop, tireless
              effort to attempt to keep up with the hackers. But, doable.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              It's important to analyze infrastructures for weaknesses, being
              proactive in addressing them, and creating disaster recovery
              plans.
            </p>
            <p className="save-your-company-from-hackers-and-disasters-page__paragraph">
              We at {specterDbaName} work hard using industry best-practices to
              protect you from the many ways you're at risk of losing your
              business by being hacked and more!{" "}
              <em>
                We're the IT managed service provider that the discerning
                business or business owner deserves to work with
              </em>
              .
            </p>
            <ContactFormGrey
              email={specterEmail}
              buttonText={"Get It Here!"}
              campaignId={"4435c0a8-70cf-4982-9d15-18b5ec808e07"}
              campaignVariant={"B"}
              phoneIsVisible={true}
              emailIsVisible={true}
            >
              Get a <em>FREE</em> business IT infrastructure{" "}
              <em>security assessment here</em>!
            </ContactFormGrey>
          </div>
        </section>
      </SaveYourCompanyFromHackersAndDisastersCore>
    </Layout>
  )
}
export default SaveYourCompanyFromHackersAndDisasters

const query = graphql`
  query {
    specterData: site {
      siteMetadata {
        company {
          name {
            dba
          }
          contact {
            email
          }
        }
      }
    }
    windowsUpdateNotification: file(
      relativePath: {
        eq: "infopage/save-your-company-from-hackers-and-disasters/windows-update.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    metasploitExample: file(
      relativePath: {
        eq: "infopage/save-your-company-from-hackers-and-disasters/msf-find-cve-2017-0199.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cveList: file(
      relativePath: {
        eq: "infopage/save-your-company-from-hackers-and-disasters/cve-list-ms-word.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fakeEmailFromBob: file(
      relativePath: {
        eq: "infopage/save-your-company-from-hackers-and-disasters/fake-email-from-bob.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
